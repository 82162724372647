.app {
  min-height: 100vh;
}
.faq-page {
  
  padding-left: 60px;
  padding-right: 60px
}
.faq-button {
  text-align: left;
  padding: 0px;
  width:430px;
  height:125px;
  border-radius:5px;
  background-color:#F7F7F7;
  border-color:#F7F7F7;
}
.faq-button:hover{
  background-color:#2D2D2A;
  border-color:#2D2D2A;
}
.faq-button > img {
  margin-left: 35px;
  vertical-align: middle;
}

.white-space-normal {
  white-space: normal !important;
}

.white-space-pre-line {
  white-space: pre-line;
}

a.custom-card:hover {
  text-decoration: none;
}

.material-icons {
  vertical-align:-14%
}


.fixed-aspect {
  position: relative;
  overflow: hidden;
}

.fixed-aspect:after {
  content: "";
  display: block;
}

.fixed-aspect>div {
  width: 100%;
  height: 100%;
  max-width: none;
  position: absolute;
}

.fixed-aspect>img {
  width: auto;
  height: 100%;
  max-width: none;
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.fixed-aspect-2-1:after {
  padding-top: 50%;
}

.fixed-aspect-4-1:after {
  padding-top: 25%;
}

.fixed-aspect-16-9:after {
  padding-top: 56.25%;
}

.fixed-aspect-4-3:after {
  padding-top: 75%;
}

.fixed-aspect-1-1:after {
  padding-top: 100%;
}

.fixed-aspect-google-badge {
  padding-top: 38.6955%
}

.fixed-aspect-responsive:after {
  padding-top: 25%;
}
.appleimg {
  vertical-align: top;
  margin: 6%;
  width: 88%;
}
.googleimg {
  vertical-align: bottom;
  width: 100%;
  margin-bottom: -6%
}
.vertical-align {
  vertical-align: top;
}
.custom-dropdown-item {
  height: 32px;
}
.google-signin-button {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url('../assets/btn_google_signin_light_normal_web.png')
}
.google-signin-button:focus {
  background-image: url('../assets/btn_google_signin_light_focus_web.png')
}
.google-signin-button:active, .google-signin-button:hover {
  background-image: url('../assets/btn_google_signin_light_pressed_web.png')
}
.facebook-signin-button {
  height: 32px;
  background-color: #4267B2;
  line-height: 16px;
}

.facebook-signin-button > img{
  height: 100%;
  width: auto;
}

.hidden 
{
  position:absolute;
  left:-10000px;
  top:auto;
  width:1px;
  height:1px;
  overflow:hidden;
}
:root{
  --download-app-height: 12vw;
  --download-app-logo-padding: 1.8vw;
  --download-app-text-height: 12vw;
  --download-app-text-size: 3vw;
  --download-app-badge-padding: 0.8vw;
  --download-app-badge-width: 15vw;
  --download-app-badge-height: 5vw;
}

@media only screen and (min-width: 576px) {
  :root{
    --download-app-height: 10vw;
    --download-app-logo-padding: 1.5vw;
    --download-app-text-height: 10vw;
    --download-app-text-size: 2.5vw;
    --download-app-badge-padding: 0.6vw;
    --download-app-badge-width: 12.5vw;
    --download-app-badge-height: 4.2vw;
  }
}

@media only screen and (min-width: 1208px) {
  :root{
    --download-app-height: 6vw;
    --download-app-logo-padding: .9vw;
    --download-app-text-height: 6vw;
    --download-app-text-size: 1.5vw;
    --download-app-badge-padding: 0.4vw;
    --download-app-badge-width: 7.5vw;
    --download-app-badge-height: 2.5vw;
  }
}

.responsive-heights {
  height: var(--download-app-height);
}
.logo-section { 
  padding: var(--download-app-logo-padding);
  width: var(--download-app-height);
}
.download-app-t {
  font-size: var(--download-app-text-size);
  line-height: var(--download-app-text-height);
}
.badge-section {
  padding: var(--download-app-badge-padding);
  width: var(--download-app-badge-width);
}
.badge-section > div {
  height: var(--download-app-badge-height);
  line-height: var(--download-app-badge-height);
}
.qr-section {
  width: var(--download-app-height);
}

.neat-height {
  min-height: 2vw;
}

.jumbo-text-outline {
  text-shadow: 1px 1px 1px black, 0 0 15px darkgray, 0 0 3px grey;
  color: white;
}

.jumbo-text {
  font-size: 3vw;
}

.pointant-button-colour {
  border-color: #4267B2;
  background-color: #4267B2;
}

.form-em-text {
  color: red;
  font-weight: bold;
}

/* Underline Nav */
/* Add a black background color to the top navigation */
.unav {
  overflow: hidden;
}

/* Style the links inside the navigation bar */
.unav a {
  border-bottom: 3px solid transparent;
}

.unav a:hover {
  border-bottom: 3px solid black;
}

.unav a.active {
  border-bottom: 3px solid black;
}


/* 
 *  Material Design Icons
 */

/* Rules for sizing the icon. */
.material-icons.md-18 { font-size: 18px; }
.material-icons.md-24 { font-size: 24px; }
.material-icons.md-26 { font-size: 26px; }
.material-icons.md-36 { font-size: 36px; }
.material-icons.md-48 { font-size: 48px; }

/* Rules for using icons as black on a light background. */
.material-icons.md-dark { color: rgba(0, 0, 0, 0.54); }
.material-icons.md-dark.md-inactive { color: rgba(0, 0, 0, 0.26); }

/* Rules for using icons as white on a dark background. */
.material-icons.md-light { color: rgba(255, 255, 255, 1); }
.material-icons.md-light.md-inactive { color: rgba(255, 255, 255, 0.3); }